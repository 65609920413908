import {del, download, get, post, put} from './index'

/**
 * 获取商品列表
 * @param data
 * @returns {Promise<unknown>}
 */
export const pagingMerchandise = (params) => {
  return get('/merchandise/list', params).then(res => res)
}

/**
 * 获取商品列表
 * @param data
 * @returns {Promise<unknown>}
 */
export const handleExportExcel = (data) => {
  return download('/merchandise/exportMerchandiseExcel', data).then(res => res)
}

export const codingMerchandise = (data) => {
  return post('/merchandise/coding', data).then(res => res)
}

/**
 * 校验商品码唯一性
 * @param param
 * @returns {Promise<T>}
 */
export const checkUniqueCode = param => {
  return get('/merchandise/checkUniqueCode', param).then(res => res)
}

/**
 * 新增商品信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const insertMerchandise = data => {
  return post('/merchandise', data).then(res => res)
}

/**
 * 修改商品信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const updateMerchandise = data => {
  return put('/merchandise', data).then(res => res)
}

/**
 * 批量修改商品信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const updateMerchandiseBatch = data => {
  return post('/merchandise/edit/batch', data).then(res => res)
}

/**
 * 删除商品信息
 * @param param
 * @returns {Promise<unknown>}
 */
export const deleteById = id => {
  return del(`/merchandise/${id}`).then(res => res)
}
/**
 * 批量删除商品信息
 * @param param
 * @returns {Promise<unknown>}
 */
export const deleteMerchandiseByIds = ids => {
  return del(`/merchandise/batchDelete/${ids}`).then(res => res)
}

/**
 * 导入商品信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const uploadMerchandise = data => {
  return post('/merchandise/upload', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => res)
}

/**
 * 批量修改商品版本号
 * @param data
 * @returns {Promise<unknown>}
 */
export const updateTaxVersion = data => {
  return post('/merchandise/updateTaxVersion', data).then(res => res)
}

/**
 * 获取商品信息列表（按税收分类名称分组）-手工-酒店
 * @param data
 * @returns {Promise<unknown>}
 */
export const getMerchandiseGroup = data => {
  return get('/merchandise/getMerchandiseGroup', data).then(res => res)
}

export const getTaxClassMerchandises = param => {
  return get(`/merchandise/getMerchandiseRemote/${param}`).then(res => res)
}

/**
 * 填充序列号
 * @param param
 * @returns {Promise<T>}
 */
export const getFillSerialNo = param => {
  return get(`/merchandise/fillSerialNo`).then(res => res)
}

/**
 * 下载商品Excel导入模板
 * @param data
 * @returns {Promise<unknown | never>}
 */
export const downExcelImportModel = data => {
  return download('/merchandise/downExcelImportModel', data).then(res => res)
}
/**
 * excel导入商品
 * @param data
 * @returns {Promise<unknown | never>}
 */
export const excelImportMerchandise = data => {
  return post('/merchandise/excelImportMerchandise', data).then(res => res)
}

/**
 * 根据税码同步租户下商品信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const syncByMerchandiseCode = data => {
  return post('/merchandise/syncByMerchandiseCode', data).then(res => res)
}
/**
 * 下载模板
 * @param data
 * @returns {Promise<T>}
 */
export const downMerchandiseTemplate = data => {
  return download('/merchandise/downMerchandiseTemplate', data).then(res => res)
}

/**
 * 批量确认商品税收分类编码
 * @param data
 * @returns {Promise<T>}
 */
export const confirmBatch = data => {
  return post('/merchandise/confirmBatch', data).then(res => res)
}

/**
 * 匹配中类名称
 * @param param
 * @returns {Promise<T>}
 */
export const getMiddleMerchandise = param => {
  return get('/merchandise/getMiddleMerchandise', param).then(res => res)
}

/**
 * 根据id获取商品信息
 * @param param
 * @returns {Promise<T>}
 */
export const getById = param => {
  return get(`/merchandise/${param}`).then(res => res);
}
